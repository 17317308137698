import React from "react"
import Detail from "../../BlogDetail/Detail"
import { Grid } from "@mui/material"
import "./Content.scss"
import "../blogpage.scss"
import VisibilitySensor from "react-visibility-sensor"
import { Link, Element } from "react-scroll"
import TalkToUs from "../../../shared/TalkToUs"
import Head from "../../../utils/Head"
import Phases from "/static/blog/Blog Detail Optimized/The 7 Strategic Phases of the Product Planning Process.jpg"
import Sprints from "/static/blog/Blog Detail Optimized/How Design Sprints and Product Discovery Play Together.jpg"

function BlogTwelve() {
  const layoutData = {
    name: "Product Strategy",
    heading: "How Design Sprints and Product Discovery Play Together",
    published: "Published: 21 Mar, 2024",
    authors: [
      {
        name: "Sikandar Lak",
        desig: "Content Strategist",
        avatar: "/aboutUs/pictures/sikander.png",
      },
      {
        name: "Hamza Munir",
        desig: "CEO",
        avatar: "/aboutUs/pictures/hamza.png",
      },
    ],
    heroImg: Sprints,
    description: (
      <>
        {"There are many variations of"} <br />{" "}
        {"passages of Lorem Ipsum available, but the majority have suffered."}
      </>
    ),

    desc: (
      <div>
        Both design sprints and product discovery are techniques used to assist
        teams in making choices more quickly and effectively. In only five days,
        teams may test new ideas and solve complicated challenges using a
        systematic method called a design sprint. Contrarily, Product Discovery
        is a method that aids teams in comprehending client demands and
        validating their concepts via study and practice. Before launching a
        major development project, teams can utilize both approaches in
        combination to better understand consumer demands and verify their
        concepts. Teams may establish a more effective and efficient product
        development process that produces superior product results by
        integrating these two approaches.
        <br />
        <br />
        Product teams are always looking for new ways to innovate to stay
        current with their consumers, differentiate themselves from competitors,
        and enhance their processes. Finding product-market fit and doing
        preliminary tests on assumptions with current and future consumers are
        essential when creating new ideas. Using a Design Sprint is a terrific
        approach to finding and verifying new product concepts.
      </div>
    ),
  }

  const checkVisibilityOff = visibility => {
    const el = document.getElementById("table-of-content")
    if (visibility) {
      el.style.display = "none"
    }
  }
  const checkVisibilityOn = visibility => {
    const el = document.getElementById("table-of-content")
    if (visibility) {
      el.style.display = "block"
      el.style.position = "fixed"
    }
  }

  return (
    <>
      <Head
        metaTitle="How Design Sprints and Product Discovery Play Together - CodeFulcrum"
        title="How Design Sprints and Product Discovery Play Together"
        desc="Discover the importance of design sprints and discovery phases in software development with CodeFulcrum’s expert insights. Learn more on our blog."
      />
      <Detail layoutData={layoutData}>
        <div className="blog-content container">
          <Grid container spacing={3}>
            <Grid item md={9}>
              <div className="talk-class">
                <TalkToUs
                  btnText={"TALK TO US"}
                  header="Want to learn how design sprints and product discovery can work together to accelerate your product development process?"
                  text={"Let our experts show you how."}
                />
              </div>
              <Element name="How Design Sprints Fit Into Product Discovery">
                <VisibilitySensor
                  onChange={checkVisibilityOn}
                  delayedCall
                  scrollCheck
                  intervalDelay={10}
                >
                  <h2 className="header">
                    How Design Sprints Fit Into Product Discovery
                  </h2>
                </VisibilitySensor>

                <p className="text">
                  Let's examine the interaction between a Design Sprint and the
                  larger activity of Product Discovery in more detail. An
                  approximate allocation results if we map activities to the
                  potential Product Discovery phases, such as 80% of a Design
                  Sprint being spent in the solution area. It involves coming up
                  with ideas, ranking them, making prototypes, and testing them
                  (at least from a qualitative angle).
                  <br />
                  <br />
                  As a result, an ideation, creation, and validation sprint is a
                  condensed version of the discovery activities. Working through
                  these phases during a DS requires numerous shortcuts, but
                  that's the point. Now, I think a DS doesn't completely take
                  the position of a Product Discovery. Some (clickbait) online
                  sites promise to be the one-stop shop for creating digital
                  items. This, in my opinion, is deceptive for the vast majority
                  of issues that are (rightly) chosen for a Design Sprint.
                  <br />
                  <br />
                  Therefore, even if the Design Sprint is one technique to cycle
                  through a few Discovery phases, it can't do the job in only
                  five days. It supports itself on the shoulders of other
                  endeavors. Design sprints and product discovery do not
                  compete. A Design Sprint may be a useful tool for a particular
                  stage of Product Discovery if the objective and context are
                  clear.
                </p>
              </Element>
              <Element name="Why are design sprints so popular today?">
                <h2 className="header">
                  Why are design sprints so popular today?
                </h2>
                <p className="text">
                  Design sprints adhere to a methodology that has been used for
                  a while in the design world. Many other design and
                  problem-solving techniques also use the double diamond
                  concept. The problem definition is the only focus of the first
                  stage, which concludes with a concise problem description.
                  Only after that do you enter the solution space, brainstorming
                  a variety of potential options before concentrating on the one
                  speculative answer that will be improved upon and tested with
                  actual consumers. The various steps can be iterated back and
                  forth during the entire procedure. The secret to success is to
                  be aware of your current phase at all times. Specifically, to
                  avoid coming up with new ideas when it would be better to
                  concentrate on comprehending the issue and to avoid expanding
                  when it would be better to focus.
                  <br />
                  <br />
                  Working in cross-functional project teams is another important
                  aspect of design sprints and product discovery. I think that
                  the rise of cross-functional teamwork in software product
                  firms is a big reason why techniques like design sprints are
                  just now becoming so well-liked. This has changed
                  substantially over the past several years, and in my opinion,
                  this has made it much easier to commit to a week-long
                  cross-functional workshop exercise.
                </p>
              </Element>
              <Element name="How can a Design Sprint help?">
                <h2 className="header">How can a Design Sprint help?</h2>
                <h3 className="sub-header">
                  Don't spread it out; limit it to a week
                </h3>
                <p className="text">
                  A team may consider a Design Sprint to be a significant
                  investment if they want to focus on a specific issue. Teams
                  nevertheless gain from spending this time together in the
                  workshop where they may concentrate and work together on the
                  same problem.
                  <br />
                  <br />
                  Having several meetings and workshops spread out over several
                  weeks is an alternative to limiting the Design Sprint to one
                  week. This method of lengthening the Design Sprint makes it
                  more difficult for teams to remain focused on the problem, and
                  crucial information and choices can easily be overlooked.
                  <br />
                  <br />
                  Aim for a short, totally devoted period during which all team
                  members are present and fully committed to the design sprint.
                  It will be simpler for individuals to leap out of it and focus
                  on other things if it is spread out over a longer length of
                  time.
                </p>
                <h3 className="sub-header">
                  It Removes Risk by Testing Prototypes
                </h3>
                <p className="text">
                  Design sprints lessen the risk of creating new products
                  without market validation. Instead of spending months creating
                  the whole solution, then testing it with clients, the risk is
                  decreased by generating a clickable (or paper) prototype in
                  one day as part of the workshop. Test with as many actual
                  users as you can, ideally five, for a successful Design
                  Sprint. Ahead of the design sprint, be sure you schedule the
                  user test meetings.
                  <br />
                  <br />
                  Teams may brainstorm, test, and learn using prototypes rather
                  than finished goods by employing this technique. Teams will
                  then be able to decide which concepts to develop, build, and
                  launch.
                </p>
                <h3 className="sub-header">
                  Make sure you are Aligned and Consider User-Friendly Items
                </h3>
                <p className="text">
                  Another advantage is that teams may test many concepts in the
                  time it would take them to fully develop only one. Teams may
                  swiftly refine a concept by taking into account the findings
                  of prototype testing.
                  <br />
                  <br />
                  Teams may be certain that what they are doing is relevant and
                  helpful to users by creating items that have been regularly
                  tested with users.
                </p>
                <VisibilitySensor
                  onChange={checkVisibilityOn}
                  delayedCall
                  scrollCheck
                  intervalDelay={10}
                >
                  <div className="talk-class">
                    <TalkToUs
                      btnText={"Read full blog"}
                      header="The 7 Strategic Phases of the Product Planning Process"
                      text={
                        "Companies utilize the product planning process, which is a rigorous and planned strategy, to find, create, and introduce new goods or to enhance current ones."
                      }
                      image={Phases}
                      ctaLink={
                        "/blogs/the-7-strategic-phases-of-the-product-planning-process"
                      }
                    />
                  </div>
                </VisibilitySensor>
              </Element>
              <Element name="Conclusion">
                <h2 className="header">Conclusion</h2>
                <VisibilitySensor
                  onChange={checkVisibilityOff}
                  delayedCall
                  scrollCheck
                  intervalDelay={10}
                >
                  <p className="text">
                    In conclusion, Design Sprints are one of many tools you can
                    use to aid in product discovery, and by scaling them up
                    throughout your company, different teams may gain from
                    quickly verifying concepts and hypotheses with consumers!
                    <br />
                    <br />
                    Providing teams with the freedom to conduct their own Design
                    Sprints will enable them to innovate. People who are
                    interested in learning can receive training from experts as
                    well as from other sources on how to run the workshop.
                    <br />
                    <br />
                    Once several teams are participating in Design Sprints, make
                    sure to share resources so that the teams may benefit from
                    one another. Additionally, make sure there is a location to
                    record the work that was covered in earlier workshops and
                    the challenge's major takeaways.
                  </p>
                </VisibilitySensor>
                <br />
                <br />
              </Element>
            </Grid>
            <Grid item md={3}>
              <div id="table-of-content" className="table-of-content">
                <p className="header-table">Table of Content</p>
                <ul>
                  <Link
                    activeClass="active"
                    to="How Design Sprints Fit Into Product Discovery"
                    smooth
                    duration={80}
                    offset={-90}
                    isDynamic
                    delay={0}
                    spy
                  >
                    <li>How Design Sprints Fit Into Product Discovery</li>
                  </Link>
                  <Link
                    activeClass="active"
                    to="Why are design sprints so popular today?"
                    smooth
                    duration={80}
                    offset={-90}
                    isDynamic
                    delay={0}
                    spy
                  >
                    <li>Why are design sprints so popular today?</li>
                  </Link>
                  <Link
                    activeClass="active"
                    to="How can a Design Sprint help?"
                    smooth
                    duration={80}
                    offset={-90}
                    isDynamic
                    delay={0}
                    spy
                  >
                    <li>How can a Design Sprint help?</li>
                  </Link>
                  <Link
                    activeClass="active"
                    to="Conclusion"
                    smooth
                    duration={80}
                    offset={-90}
                    isDynamic
                    delay={0}
                    spy
                  >
                    <li>Conclusion</li>
                  </Link>
                </ul>

                <p className="share">Share</p>
                <div className="link-icons d-flex justify-content-between">
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                  >
                    <img
                      src="/homepage/linkedin.svg"
                      alt="linkedin"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </a>

                  <a
                    href="https://instagram.com/"
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                  >
                    <img
                      src="/homepage/instagram.svg"
                      alt="instagram"
                      className="img-fluid"
                      loading="lazy"
                      id="insta"
                    />
                  </a>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    className="social-icon"
                    rel="noreferrer"
                  >
                    <img
                      src="/homepage/facebook.svg"
                      alt="facebook"
                      className="img-fluid"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </Detail>
    </>
  )
}

export default BlogTwelve
