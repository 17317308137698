import React from "react"
import BlogLayout from "../../components/Blog/BlogLayout"
import BlogTwelve from "../../components/Blog/BlogsList/BlogTwelve"
const BlogContent = () => {
  return (
    <BlogLayout>
      <BlogTwelve />
    </BlogLayout>
  )
}

export default BlogContent
